import { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import axiosService from "../../services/axiosService";
import { toast } from 'react-toastify';

const EngagementForm = ({ handleClose }) => {
  const [formEngagement, setFormEngagement] = useState({
    categorie: "Adhésion",
    nom: "",
    email: "",
    entreprise: "",
    message: "",
  });
  const [isLoadingEngagement, setIsLoadingEngagement] = useState(false);
  const handleChangeEngagement = (e) => {
    const { name, value } = e.target;
    setFormEngagement((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmitEngagement = async (e) => {
    e.preventDefault();
    setIsLoadingEngagement(true);

    try {
      formEngagement.message = formEngagement.message.replace(/\n/g, "<br>");
      if (formEngagement.entreprise.trim() === '') {
        formEngagement.entreprise = 'N.C';
      }
      const response = await axiosService.post('/email/engagement', JSON.stringify(formEngagement));

      if (response.status === 200) {
        let message = response.data.message;
        toast(message, {
          type: 'success',
          progress: 0,
        });
        setFormEngagement({
          categorie: "",
          nom: "",
          email: "",
          entreprise: "",
          message: "",
        });
      } else {
        toast('Oups, une erreur interne s\est produite.', {
          type: 'error',
          progress: 0,
        });
      }
    } catch (error) {
      let erreur = error.response.data.errors[0];
      toast(erreur, {
        type: 'error',
        progress: 0,
      });
    } finally {
        setIsLoadingEngagement(false);
        handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmitEngagement} className="custom-form engagement-form mb-5 mb-lg-0" role="form">
      <div className="contact-form-body">
        <div className="row">
          
          <div className="col-12 mb-3">
            <p className="mb-1">Votre demande concerne : </p>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="categorie" id="categorie1" value="Adhésion" checked={formEngagement.categorie === 'Adhésion'} onChange={handleChangeEngagement} />
              <label className="form-check-label" htmlFor="categorie1">Adhésion</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="categorie" id="categorie2" value="Partenariat" checked={formEngagement.categorie === 'Partenariat'} onChange={handleChangeEngagement} />
              <label className="form-check-label" htmlFor="categorie2">Partenariat</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="radio" name="categorie" id="categorie3" value="Bénévolat" checked={formEngagement.categorie === 'Bénévolat'} onChange={handleChangeEngagement} />
              <label className="form-check-label" htmlFor="categorie3">Bénévolat</label>
            </div>
          </div>

        </div>
        <div className="row">
            <div className="col-12">
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  className="form-control"
                  placeholder="Nom complet"
                  value={formEngagement.nom}
                  onChange={handleChangeEngagement}
                  required  />
            </div>

            <div className="col-12">
                <input
                  type="email"
                  name="email"
                  id="email"
                  pattern="[^ @]*@[^ @]*"
                  className="form-control"
                  placeholder="Adresse e-mail"
                  value={formEngagement.email}
                  onChange={handleChangeEngagement}
                  required />
            </div>
        </div>

        <input
          type="text"
          name="entreprise"
          id="entreprise"
          className="form-control"
          placeholder="Entreprise / Association"
          value={formEngagement.entreprise}
          onChange={handleChangeEngagement}
        />

        <textarea
          name="message"
          id="message"
          rows="10"
          className="form-control"
          required
          value={formEngagement.message}
          onChange={handleChangeEngagement}
          placeholder="Message"></textarea>

        <div className="col-lg-4 col-md-10 col-8 mx-auto">
            <button disabled={isLoadingEngagement} type="submit" className="form-control custom-btn">
              { isLoadingEngagement ? <FaSpinner className="spinner" /> : <i className="fas fa-paper-plane"></i> } Envoyer
            </button>
        </div>
      </div>
    </form>
  )
}

export default EngagementForm;
