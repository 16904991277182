function Footer({ socials, liensUtiles })
{
  return (
    <footer className="site-footer">
        <div className="site-footer-top">
            <div className="container">                
                <div className="row">

                    <div className={socials.length > 0 ? 'col-lg-6' : 'text-center'}>
                        <h4 className="text-white mb-lg-0">Courses Hippiques Rurales</h4>
                    </div>

                    {socials.length > 0 &&
                        <div className="col-lg-6 col-12 d-flex justify-content-lg-end align-items-center">
                            <span className="text-white me-3">Retrouvez-nous sur</span>

                            <ul className="social-icon d-flex justify-content-lg-end">
                                {socials.map(( social, index ) => (
                                    <li className="social-icon-item">
                                        <a href={social.lien} target="_blank" className="social-icon-link" title={social.survol}>
                                            <span className={social.icone}></span>
                                        </a>
                                    </li>    
                                ))}
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div className="container">
            <div className="row">

                <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0" >
                    <h5 className="site-footer-title mb-3">Liens utiles</h5>

                    {liensUtiles.length > 0 &&
                        liensUtiles.map((lien, index) => (
                            <p className="text-white d-flex mb-1">
                                <a href={lien.url} className="site-footer-link" target="_blank">
                                    {lien.libelle}
                                </a>
                            </p>
                        ))
                    }
                </div>
                
                <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                    <h5 className="site-footer-title mb-3">Une question ?</h5>

                    <p className="text-white d-flex mb-1">
                        <a href="tel: 090-080-0760" className="site-footer-link">
                            07 66 49 74 85
                        </a>
                    </p>

                    <p className="text-white d-flex">
                        <a href="mailto:kiki.rodeo@orange.fr" className="site-footer-link">
                            kiki.rodeo@orange.fr
                        </a>
                    </p>
                </div>

                <div className="col-lg-4 col-md-6 col-12 mb-4 mb-lg-0">
                    <h5 className="site-footer-title mb-3">Juridique</h5>

                    <p className="text-white d-flex mb-1">
                        Association à but non lucratif
                    </p>

                    <p className="text-white d-flex">
                        N° SIRET : 362 521 879 00034
                    </p>

                    {/*<svg className="icon" viewBox="0 0 32 32" aria-hidden="true">
                        <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
                            <circle cx="16" cy="16" r="15.5"></circle>
                            <line x1="10" y1="18" x2="16" y2="12"></line>
                            <line x1="16" y1="12" x2="22" y2="18"></line>
                        </g>
                    </svg>*/}
                </div>
            </div>
        </div>

        <div className="site-footer-bottom">
            <div className="container">
                <div className="row">

                    <div className="col-lg-3 col-12 mt-5">
                        <p className="copyright-text">Copyright © CHR 2024</p>
                    </div>

                    <div className="col-lg-6 col-12 mt-lg-5">
                        <ul className="site-footer-links">
                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Conditions Générales</a>
                            </li>

                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Politique de confidentialité</a>
                            </li>

                            <li className="site-footer-link-item">
                                <a href="#" className="site-footer-link">Vos réactions</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-12 mt-5">
                        <p className="copyright-text">Développé par <a href="https://aziz-bennane.fr" target="_blank" title="Développeur Fullstack">Aziz BENNANE</a></p>
                    </div>

                </div>
            </div>
        </div>
    </footer>
  );
}

export default Footer;